<script>
import { required, email } from "vuelidate/lib/validators";
import { parseErrors } from '../../../helpers'

import {
  authMethods,
  notificationMethods
} from "@/state/helpers";

export default {
  data() {
    return {
      email: "",
      password: "",
      accountId: null,
      submitted: false,
      errors:[],
      tryingToLogIn: false,
      accounts:[]
    };
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    }
  },
  created() {
    document.body.classList.add("auth-body-bg");
  },
  validations: {
    email: { required, email },
    password: { required }
  },
  methods: {
    ...authMethods,
    ...notificationMethods,
    // Try to log the user in with the email
    // and password they provided.
    tryToLogIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
          this.tryingToLogIn = true;
        
          this.errors = [];
          const { email, password, accountId } = this;
          if (email && password) {
            this.logIn({ email, password, accountId })
            .then(() => {
                this.tryingToLogIn = false;
                this.$router.push(
                    this.$route.query.redirectFrom || { name: "home" }
                  );
              })
              .catch(error => {
                  this.errors = parseErrors(error);
                  this.tryingToLogIn = false; 
              });
          }
      }
    }
  }
};
</script>

<template>
  <div>
    
    <div  class="container p-0 ">
      <div>
         <div class="gr-bg text-center p-4 mt-4 mb-4 border-radius" data-aos="fade-right">
            <div class="row">
                <div class="col-xl-4 col-lg-3 col-md-2">

                </div>
                <div class="col-xl-4 col-lg-6  col-md-8" style="position: relative">
                    <img src="@/assets/images/star1.png" class="img-fluid spin-image"/>
                    <span style="position: absolute; left: 0; right: 0; top: 0; bottom: 0;display: flex;justify-content: center;align-items: center;" class="text-center">
                        <img src="@/assets/images/logo_white.png" alt="LOGO" class="img-fluid " style=" height: 60px;"/>
                    </span>
                </div>
                <div class="col-xl-4  col-lg-3  col-md-2">

                </div>
            </div>
                   
          </div>
         <b-alert
            variant="danger"
            class="mt-3 mb-3"
            v-if="errors.length > 0"
            show
            dismissible
          >
          <p>
            The email or password is incorrect.
          </p>
        </b-alert>
        <form class="form-horizontal mt-4" @submit.prevent="tryToLogIn">
          <div class="row" v-if="accounts && accounts.length === 0">
            <div class="col-md-6">
              <div class="form-group auth-form-group-custom mb-4">
                <i class="ri-mail-line auti-custom-input-icon"></i>
                <label for="email">E-mail (*)</label>
                <input
                  type="email"
                  v-model="email"
                  class="form-control"
                  id="email"
                  placeholder=""
                  :class="{ 'is-invalid': submitted && $v.email.$error }"
                />
                <div v-if="submitted && $v.email.$error" class="invalid-feedback">
                  <span v-if="!$v.email.required">{{$t("pages.login.email-required")}}</span>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group auth-form-group-custom mb-4">
                <i class="ri-lock-2-line auti-custom-input-icon"></i>
                <label for="userpassword">{{$t("pages.login.password")}}</label>
                <input
                  v-model="password"
                  type="password"
                  class="form-control"
                  id="userpassword"
                  placeholder=""
                  :class="{ 'is-invalid': submitted && $v.password.$error }"
                />
                <div
                  v-if="submitted && !$v.password.required"
                  class="invalid-feedback"
                >{{$t("password-required")}}</div>
              </div>
            </div>
           
          </div>
          <div class="row" v-if="accounts && accounts.length > 0">
             <div class="col-md-12">
              <div class="form-group auth-form-group-custom mb-4">
                <i class="ri-home-8-line auti-custom-input-icon"></i>
                <label for="account">Choose account</label>
                <select class="form-control" id="account" v-model="accountId">
                  <option :value="null">
                    --- No account selected ---
                  </option>
                  <option v-for="account in accounts" :key="account.id" :value="account.id">{{account.text}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col text-right">
              <button
                class="btn btn-primary w-md waves-effect waves-light mt-4"
                type="submit"
                :disabled="accounts && accounts.length > 0 && !accountId"
              >
                <i class="fa fa-spin fa-spinner mr-2" v-if="tryingToLogIn"></i>
                <span v-if="!accounts || accounts.length === 0">Login</span>
                <span v-if="accounts && accounts.length > 0">Apply and Login</span>
              </button>
            </div>
          </div>
        </form>
        
      </div>
    </div>
  </div>
</template>